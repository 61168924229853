import { FC } from "react"
import MobileFirstLayout from "../Layout/MobileFirstLayout"
import useTranslation from "next-translate/useTranslation"
import styled from "styled-components"
import {
  PrimaryButtonLink,
  DefaultButtonLink,
} from "@hornet-web-react/core/components/UI/TextButton"
import GuestVisitorContentWrapper from "../Layout/GuestVisitorContentWrapper"
import { UTM_SOURCE } from "@hornet-web-react/core/utils/constants"
import DownloadHornetApp from "../DownloadHornetApp"

type PageLandingProps = {
  //
}

const Line1 = styled.h1`
  ${({ theme }) => theme.font.bold.title2};
  color: ${({ theme }) => theme.color.text.primary};
  margin: ${({ theme }) => theme.spacing.regular} 0
    ${({ theme }) => theme.spacing.less};
  text-align: center;
`

const Line2 = styled.h2`
  ${({ theme }) => theme.font.regular.title3};
  color: ${({ theme }) => theme.color.text.secondary};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.regular};
  text-align: center;
`

const Line3 = styled.p`
  ${({ theme }) => theme.font.regular.body};
  color: ${({ theme }) => theme.color.text.tertiary};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.more};
  text-align: center;
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const PageLanding: FC<PageLandingProps> = () => {
  const { t } = useTranslation()

  return (
    <MobileFirstLayout leftPanel={undefined} hasPadding={false}>
      <GuestVisitorContentWrapper>
        <Line1>{t("hornet:components.pages.page_landing.text.line1")}</Line1>
        <Line2>{t("hornet:components.pages.page_landing.text.line2")}</Line2>
        <Line3>{t("hornet:components.pages.page_landing.text.line3")}</Line3>
        <ButtonGroup>
          <PrimaryButtonLink href={"/signup"}>
            {t("hornet:components.pages.page_landing.buttons.signup")}
          </PrimaryButtonLink>
          <DefaultButtonLink href={"/login"}>
            {t("hornet:components.pages.page_landing.buttons.login")}
          </DefaultButtonLink>
        </ButtonGroup>
        <Divider />
        <DownloadHornetApp utmSource={UTM_SOURCE.HOME_TOP} utmCampaign={null} />
      </GuestVisitorContentWrapper>
    </MobileFirstLayout>
  )
}

export default PageLanding

const Divider = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.color.border.default};
  margin: ${({ theme }) => theme.spacing.double} 0;
  padding: 0;
`
