import { FC, PropsWithChildren } from "react"
import styled from "styled-components"

type GuestVisitorContentWrapperProps = PropsWithChildren

const GuestVisitorContentWrapper: FC<GuestVisitorContentWrapperProps> = ({
  children,
}) => {
  return (
    <Wrapper>
      <Background>{children}</Background>
    </Wrapper>
  )
}

export default GuestVisitorContentWrapper

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  flex: 1 1 auto;
  background: ${({ theme }) => theme.color.bg.light01};

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    border-left: 1px solid ${({ theme }) => theme.color.border.default};
    border-right: 1px solid ${({ theme }) => theme.color.border.default};
  }
`

const Background = styled.div`
  background: ${({ theme }) => theme.color.bg.light01};
  padding: ${({ theme }) => theme.spacing.regular};
`
