import { FC } from "react"
import HornetLogoIcon from "@hornet-web-react/core/components/Icons/HornetLogoIcon"
import AppStoreBadges from "./AppStoreBadges"
import styled from "styled-components"
import useTranslation from "next-translate/useTranslation"

type DownloadHornetAppProps = {
  utmSource: string
  utmCampaign: string | null
  small?: boolean
  className?: string
}

const DownloadHornetApp: FC<DownloadHornetAppProps> = ({
  utmSource,
  utmCampaign,
  small = false,
  className,
}) => {
  const { t } = useTranslation()

  return (
    <Wrapper className={className}>
      <HornetLogoIcon width={"60px"} height={"60px"} />
      <Title>{t(`hornet:components.download_hornet_app.title`)}</Title>
      <AppStoreBadges
        utmSource={utmSource}
        utmCampaign={utmCampaign}
        small={small}
      />
    </Wrapper>
  )
}

export default DownloadHornetApp

const Wrapper = styled.div`
  flex: 1;
  text-align: center;
  padding: $spacing-regular 0;
`

const Title = styled.h3`
  color: #1b1b1b;
  ${({ theme }) => theme.font.regular.title2};
  color: ${({ theme }) => theme.color.text.secondary};
  padding: 0;
  margin: ${({ theme }) => theme.spacing.regular} 0;
`
