import Layout from "../components/Layout"
import appConfig from "../app-config"
import PageLanding from "../components/Pages/PageLanding"
import getStaticSessionProps from "@hornet-web-react/core/middleware/next-props/get-static-session"
import { useEffect } from "react"
import { useSessionUser } from "@hornet-web-react/core/contexts/session"
import { useNavigation } from "../hooks/use-navigation"
import useTranslation from "next-translate/useTranslation"

export default function Page() {
  const { t } = useTranslation()
  const { navigateHome } = useNavigation()
  const { isAuthenticated } = useSessionUser()

  // redirect to feed (/user) if logged in
  useEffect(() => {
    if (isAuthenticated) {
      void navigateHome()
    }
  }, [isAuthenticated, navigateHome])

  return (
    <Layout
      showFooter={true}
      showTopNavBar={true}
      showBottomNavBar={false}
      hideTopNavBarMenu={true}
      title={t("hornet:components.pages.page_landing.text.line1")}
    >
      <PageLanding />
    </Layout>
  )
}

export async function getStaticProps(context) {
  return getStaticSessionProps(appConfig, context, {
    environment: {
      isInApp: false,
      inAppVersion: null,
    },
  })
}
