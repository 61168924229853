import PropTypes from "prop-types"
import useTranslation from "next-translate/useTranslation"
import * as React from "react"

const HornetLogoIcon = (props: HornetLogoIconProps) => {
  const { t } = useTranslation()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 394}
      height={props.height || 386}
      viewBox="0 0 394 386"
      role="img"
      {...props}
    >
      <title>{t("core:components.icons.hornet_logo_icon")}</title>
      <g fillRule="nonzero" fill="none">
        <path
          d="M196.661 386c108.616 0 196.661-86.379 196.661-192.937S305.277.119 196.661.119 0 86.505 0 193.063C0 299.621 88.045 386 196.661 386"
          fill="#DF5437"
        />
        <path
          d="M210.618 236.652c18.909-23.11 38.755-48.202 57.798-71.485l-66.678-13.254c-5.349-1.094-9.227.039-12.775 4.176-5.6 6.545-11.491 12.854-17.398 19.138-2.683 2.863-3.43 5.931-2.329 9.644 3.351 11.319 6.623 23.723 9.99 35.034.259.866.849 1.982 1.581 2.305l29.811 14.442M84.189 121.525l67.39-21.669c1.951-.582 3.288-.354 4.901.928l63.166 49.851-135.457-29.11"
          fill="#FFFFFE"
        />
        <path
          d="M229.087 152.774c-7.395-5.813-14.057-12.122-20.711-17.368-16.943-13.348-33.894-26.673-50.782-40.092-1.557-1.235-2.832-1.376-4.633-.771-6.442 2.187-12.955 4.185-19.413 6.332-1.463.488-2.485.637-3.579-.873L98.758 56.59c9.045 3.917 17.548 7.599 26.051 11.288 26.642 11.547 53.291 23.062 79.885 34.704 1.464.645 2.934 2.116 3.611 3.571l20.782 46.621M205.284 239.81c2.187 1.061 3.06 2.045 2.643 4.703l-2.619 23.598-84.762-9.359 23.244-25.149c9.998-10.736 18.496-13.922 29.63-9.179l31.864 15.386M204.916 273.334c-13.734 15.88-27.279 31.542-41.092 47.517l-42.966-56.556 84.058 9.039M272.732 167.27c6.073 6.945 12.145 14.812 17.998 21.797.96 1.156 1.635 3.264 1.313 4.672-2.367 10.564-6.348 20.459-13.419 28.844-4.932 5.86-11.052 9.793-19.279 10.816-1.007-3.729-2.069-7.52-3.052-11.327-2.037-7.835-4.09-15.669-5.994-23.543-.267-1.093-.181-2.713.465-3.524l21.968-27.735M192.814 295.912c1.148-1.188 2.037-1.463 3.594-.677l55.179 29.687c-9.297 1.25-18.138 2.438-26.972 3.618-5.663.755-11.303 1.794-16.998 2.163-4.656.291-9.478.362-14.032-.495-8.44-1.581-16.731-3.91-25.965-6.136l25.194-28.16M300.255 158.071c.817 3.162 1.675 6.316 2.415 9.502.133.574-.079 1.384-.417 1.888-2.935 4.389-5.947 8.731-9.581 12.836l7.583-24.226"
          fill="#FFFFFE"
        />
      </g>
    </svg>
  )
}

type HornetLogoIconProps = {
  width?: string
  height?: string
}

export default HornetLogoIcon

HornetLogoIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
}
